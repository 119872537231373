// Only helpers classes, not overrides, not mixins, nothing else but helpers

.content-info {
  border-radius: var(--border-radius-small);
  background: var(--Notification-Info-background-info, #c2e6ff);
  padding: 8px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.bg-info {
  background-color: var(--blue-150);
  color: var(--blue-850);
}
