@mixin confirmBtn {
  background: var(--viridian-800);
  color: white;
  border-radius: var(--Button-Radius-radius-square, 8px);
  font-family: PitchSans-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

@mixin cancelBtn {
  font-family: PitchSans-Medium;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

.poplin-alert {
  &.sc-ion-alert-md-h {
    --max-width: 92.5%;
    --min-width: 92.5%;
  }

  .alert-wrapper.sc-ion-alert-md {
    border-radius: var(--border-radius-default);
    padding: 36px 13px;
  }

  .alert-head.sc-ion-alert-md {
    padding-bottom: 0;
  }

  .alert-button-group.sc-ion-alert-md {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 25px;
  }

  .alert-button.sc-ion-alert-md {
    &.alert-button-role-submit {
      margin-inline-end: unset;
      margin-block-end: 8px;
      text-align: center;
      width: 100%;
      @include confirmBtn;
    }

    &.alert-button-role-cancel {
      margin-block-start: 12px;
      text-align: center;
      width: 100%;
      @include cancelBtn;
    }
  }

  .alert-button-inner.sc-ion-alert-md {
    justify-content: center;
  }

  .alert-title.sc-ion-alert-md {
    font-family: 'Fakt-Bold';
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-block-end: 4px;
  }

  .alert-message.sc-ion-alert-md {
    font-family: 'Fakt-Normal';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding-bottom: 24px;
  }

  .alert-button.sc-ion-alert-md {
    &:not(.alert-button-role-cancel) {
      @include confirmBtn;
    }
    &.alert-button-role-cancel {
      @include cancelBtn;
    }
  }
}
