ion-select.poplin-theme {
  height: 48px;
  padding: var(--Form-Fields-Padding-vertical-small)
    var(--Form-Fields-Padding-horizontal-small) !important;
  border-radius: var(--border-radius-default);
  border: 1px solid var(--Form-Fields-Border-default);
  background: var(--Form-Fields-Background-default);
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;

  &.ion-invalid {
    border: 1px solid var(--pink-700);
  }

  &::part(icon) {
    display: none;
  }
}
