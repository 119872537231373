/* Typographic mixin styles */
/* ================================================================== */

/*
    BODY TEXT
    used in paragraphs, snippets
*/
@mixin body-text {
  font-family: 'Fakt-Normal', sans-serif;
  font-weight: 400;
  line-height: 1.4;
}
body {
  @include body-text;
}

/* Body text sizes */
@mixin xl-body {
  @include body-text;
  font-size: 1.5rem;
}
@mixin r-body {
  @include body-text;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
@mixin l-body {
  @include body-text;
  font-size: 1.25rem;
}
@mixin m-body {
  @include body-text;
  font-size: 1rem;
}
@mixin s-body {
  @include body-text;
  font-size: 0.8125rem;
}

/*
    HEADINGS
*/

@mixin heading {
  font-family: 'PitchSans-Medium', Helvetica, sans-serif;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: 0;
}

@mixin ion-container-header {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.72px;
}

@mixin ion-header {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

h1,
h2,
h3,
h4,
h5 {
  @include heading;
}

/* Head sizes */
@mixin xxl-head {
  @include heading;
  font-size: 5rem;
}
@mixin xl-head {
  @include heading;
  font-size: 3.5rem;
}
@mixin l-head {
  @include heading;
  font-size: 3rem;
}
@mixin m-head {
  @include heading;
  font-size: 2.25rem;
}
@mixin s-head {
  @include heading;
  font-size: 1.875rem;
}
@mixin xs-head {
  @include heading;
  font-size: 1.5rem;
}

/*
    ALT HEADINGS
    Fakt Pro-styled headings for use under Heading Small sizes (includes Bold and Underlined variants)
*/

@mixin l-alt-head {
  @include body-text;
  font-family: 'Fakt-Bold', 'Manrope-Bold', Helvetica, sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
@mixin m-alt-head {
  @include body-text;
  font-family: 'Fakt-Bold', 'Manrope-Bold', Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.375rem;
}
@mixin s-alt-head {
  @include body-text;
  font-family: 'Fakt-Bold', 'Manrope-Bold', Helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

/*
    SUBHEADS
*/

@mixin subhead {
  font-family: 'PitchSans-Bold', Helvetica, sans-serif;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

/* Subhead sizes */
@mixin xxl-subhead {
  @include subhead;
  font-size: 3rem;
}
@mixin xl-subhead {
  @include subhead;
  font-size: 2.25rem;
}
@mixin l-subhead {
  @include subhead;
  font-size: 1.875rem;
}
@mixin m-subhead {
  @include subhead;
  font-size: 1.5rem;
}
@mixin s-subhead {
  @include subhead;
  font-size: 1rem;
}
@mixin xs-subhead {
  @include subhead;
  font-size: 0.8125rem;
}

@mixin h2-subhead {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

/*
    AUX TEXT
    currently only used in buttons
*/

@mixin ion-chip {
  // set color
  // --background: var(--xxx-nnn);

  flex: none;
  width: fit-content;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.3px;
}

@mixin aux-text {
  font-family: 'PitchSans-Bold', Helvetica, sans-serif;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

/* Aux text sizes */
@mixin l-aux {
  @include aux-text;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

@mixin m-aux {
  @include aux-text;
  font-size: 1rem;
  line-height: 1.125rem;
}

@mixin s-aux {
  @include aux-text;
  font-size: 0.8125rem;
  line-height: 1.125rem;
}

@mixin title-btn {
  font-family: PitchSans-Medium;
  font-size: 16px !important;
  font-weight: 700;
  line-height: 19px !important;
  letter-spacing: 2.4px;
}
