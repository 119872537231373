ion-checkbox.poplin-theme {
  --size: 32px;
  --checkbox-background-checked: var(--viridian-800);
  --border-radius: 4px;

  &.checkbox-checked {
    --border-width: 7px;
  }

  &.checkbox-disabled {
    --checkbox-background-checked: var(--gray-250);
    --checkbox-background: var(--gray-250);
    --border-color-checked: var(--gray-250);
    --border-color: var(--gray-250);
    --checkmark-color: var(--gray-650);
  }
}
