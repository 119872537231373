ion-toggle {
  --track-background: var(--gray-650);
  --track-background-checked: var(--viridian-800);

  --handle-background: var(--white);
  --handle-background-checked: var(--white);

  --handle-width: 28px;
  --handle-height: 28px;
  --handle-max-height: auto;
  --handle-spacing: 6px;

  --handle-border-radius: 50%;
  --handle-box-shadow: none;
}

ion-toggle::part(track) {
  height: 36px;
  width: 60px;
  border-radius: 30px;
  overflow: visible;
}

.toggle-disabled {
  --track-background: var(--gray-250);
  opacity: 1;
}
