ion-button.poplin-theme {
  --box-shadow: none;
  --border-radius: var(--border-radius-default);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.3px;
}
ion-button.poplin-theme.button-disabled {
  opacity: 1;
}
ion-button.poplin-theme.h-large {
  --border-radius: 8px;
  height: 48px;
  font-size: 16px;
  line-height: 20px;
}
ion-button.poplin-theme span.title {
  margin-right: 10px;
}

ion-button[disabled].poplin-theme {
  --ion-color-base: var(--gray-650) !important;
}

ion-checkbox.poplin-theme {
  --size: 32px;
  --checkbox-background-checked: var(--viridian-800);
  --border-radius: 4px;
}
ion-checkbox.poplin-theme.checkbox-checked {
  --border-width: 7px;
}
ion-checkbox.poplin-theme.checkbox-disabled {
  --checkbox-background-checked: var(--gray-250);
  --checkbox-background: var(--gray-250);
  --border-color-checked: var(--gray-250);
  --border-color: var(--gray-250);
  --checkmark-color: var(--gray-650);
}

ion-input.poplin-theme,
input.poplin-theme {
  height: 48px;
  padding: var(--Form-Fields-Padding-vertical-small) var(--Form-Fields-Padding-horizontal-small) !important;
  border-radius: var(--border-radius-default);
  border: 1px solid var(--Form-Fields-Border-default);
  background: var(--Form-Fields-Background-default);
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  transition: all 0.25s ease-in;
}
ion-input.poplin-theme.ng-dirty.ng-touched.ng-invalid,
input.poplin-theme.ng-dirty.ng-touched.ng-invalid {
  border-color: var(--pink-700);
}
ion-input.poplin-theme[disabled],
input.poplin-theme[disabled] {
  background: var(--Form-Fields-Background-disabled);
}
ion-input.poplin-theme:focus,
input.poplin-theme:focus {
  border-color: var(--Form-Fields-Border-focus);
}

ion-toggle {
  --track-background: var(--gray-650);
  --track-background-checked: var(--viridian-800);
  --handle-background: var(--white);
  --handle-background-checked: var(--white);
  --handle-width: 28px;
  --handle-height: 28px;
  --handle-max-height: auto;
  --handle-spacing: 6px;
  --handle-border-radius: 50%;
  --handle-box-shadow: none;
}

ion-toggle::part(track) {
  height: 36px;
  width: 60px;
  border-radius: 30px;
  overflow: visible;
}

.toggle-disabled {
  --track-background: var(--gray-250);
  opacity: 1;
}

ion-toast {
  --border-radius: var(--border-radius-default);
}
ion-toast::part(container) {
  background: transparent;
  border-radius: var(--border-radius-default);
}
ion-toast::part(header) {
  font-family: "PitchSans-Medium", "Helvetica", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 2.1px;
}
ion-toast::part(message) {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
ion-toast::part(icon) {
  border-radius: 50%;
  border: 3px solid white;
}

.success-toast::part(container) {
  background: var(--green-350);
  color: var(--green-900);
}
.success-toast::part(icon) {
  color: var(--green-500);
  background: white;
}

.error-toast::part(container) {
  background: var(--pink-400);
  color: var(--pink-950);
}
.error-toast::part(icon) {
  color: var(--pink-500);
  background: white;
}

ion-select.poplin-theme {
  height: 48px;
  padding: var(--Form-Fields-Padding-vertical-small) var(--Form-Fields-Padding-horizontal-small) !important;
  border-radius: var(--border-radius-default);
  border: 1px solid var(--Form-Fields-Border-default);
  background: var(--Form-Fields-Background-default);
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
}
ion-select.poplin-theme.ion-invalid {
  border: 1px solid var(--pink-700);
}
ion-select.poplin-theme::part(icon) {
  display: none;
}

.poplin-alert.sc-ion-alert-md-h {
  --max-width: 92.5%;
  --min-width: 92.5%;
}
.poplin-alert .alert-wrapper.sc-ion-alert-md {
  border-radius: var(--border-radius-default);
  padding: 36px 13px;
}
.poplin-alert .alert-head.sc-ion-alert-md {
  padding-bottom: 0;
}
.poplin-alert .alert-button-group.sc-ion-alert-md {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
}
.poplin-alert .alert-button.sc-ion-alert-md.alert-button-role-submit {
  margin-inline-end: unset;
  margin-block-end: 8px;
  text-align: center;
  width: 100%;
  background: var(--viridian-800);
  color: white;
  border-radius: var(--Button-Radius-radius-square, 8px);
  font-family: PitchSans-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}
.poplin-alert .alert-button.sc-ion-alert-md.alert-button-role-cancel {
  margin-block-start: 12px;
  text-align: center;
  width: 100%;
  font-family: PitchSans-Medium;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}
.poplin-alert .alert-button-inner.sc-ion-alert-md {
  justify-content: center;
}
.poplin-alert .alert-title.sc-ion-alert-md {
  font-family: "Fakt-Bold";
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-block-end: 4px;
}
.poplin-alert .alert-message.sc-ion-alert-md {
  font-family: "Fakt-Normal";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 24px;
}
.poplin-alert .alert-button.sc-ion-alert-md:not(.alert-button-role-cancel) {
  background: var(--viridian-800);
  color: white;
  border-radius: var(--Button-Radius-radius-square, 8px);
  font-family: PitchSans-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}
.poplin-alert .alert-button.sc-ion-alert-md.alert-button-role-cancel {
  font-family: PitchSans-Medium;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

/* Typographic mixin styles */
/* ================================================================== */
/*
    BODY TEXT
    used in paragraphs, snippets
*/
body {
  font-family: "Fakt-Normal", sans-serif;
  font-weight: 400;
  line-height: 1.4;
}

/* Body text sizes */
/*
    HEADINGS
*/
h1,
h2,
h3,
h4,
h5 {
  font-family: "PitchSans-Medium", Helvetica, sans-serif;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: 0;
}

/* Head sizes */
/*
    ALT HEADINGS
    Fakt Pro-styled headings for use under Heading Small sizes (includes Bold and Underlined variants)
*/
/*
    SUBHEADS
*/
/* Subhead sizes */
/*
    AUX TEXT
    currently only used in buttons
*/
/* Aux text sizes */
ion-modal.poplin-theme {
  --border-radius: var(--border-radius-default);
  --box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);
}
ion-modal.poplin-theme ion-toolbar {
  padding-top: 0;
}
ion-modal.poplin-theme ion-header {
  box-shadow: none;
}
ion-modal.poplin-theme ion-header ion-title {
  font-family: "Fakt-Normal", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  font-family: "Fakt-Bold", "Manrope-Bold", Helvetica, sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
ion-modal.poplin-theme ion-content {
  --padding-end: 10px;
  --padding-start: 10px;
}

ion-modal.datepicker-modal {
  --height: 670px;
  --width: 95%;
  --border-radius: var(--border-radius-default);
  --box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);
}
ion-modal.datepicker-modal.modal-default {
  top: 0;
  bottom: 0;
}

.footer-md {
  box-shadow: none;
}