/* poplin styles */
/* colors */

:root {
  /* pink */
  --pink-50: #fff5f7;
  --pink-100: #ffebf0;
  --pink-150: #ffebf0;
  --pink-200: #ffcbd8;
  --pink-250: #ffc1d0;
  --pink-300: #ffb5c8;
  --pink-350: #ffa9bf;
  --pink-400: #ff9cb5;
  --pink-450: #ff8da9;
  --pink-500: #ff7c9d;
  --pink-550: #ff6289;
  --pink-600: #ff4b78;
  --pink-650: #ff245b;
  --pink-700: #f5003d;
  --pink-750: #d60035;
  --pink-800: #9b0027;
  --pink-850: #530015;
  --pink-900: #38000e;
  --pink-950: #140005;
  --pink-core: var(--pink-550);

  /* purple */
  --purple-50: #f9f8fc;
  --purple-100: #f1ecf8;
  --purple-150: #e4dcf2;
  --purple-200: #ddd4ef;
  --purple-250: #d7cced;
  --purple-300: #d0c4ea;
  --purple-350: #c9bae6;
  --purple-400: #c0afe2;
  --purple-450: #baa7df;
  --purple-500: #b09bdb;
  --purple-550: #a78fd6;
  --purple-600: #9c82d2;
  --purple-650: #9173cc;
  --purple-700: #8361c6;
  --purple-750: #714bbe;
  --purple-800: #573798;
  --purple-850: #2c1c4d;
  --purple-900: #1a102d;
  --purple-950: #0d0816;
  --purple-core: var(--purple-400);

  /* blue */
  --blue-50: #f5fbff;
  --blue-100: #e5f4ff;
  --blue-150: #c2e6ff;
  --blue-200: #b0deff;
  --blue-250: #a1d8ff;
  --blue-300: #8fd0ff;
  --blue-350: #7ec9ff;
  --blue-400: #6ac1ff;
  --blue-450: #54b8ff;
  --blue-500: #3aadff;
  --blue-550: #1da1ff;
  --blue-600: #0093fc;
  --blue-650: #0085e4;
  --blue-700: #0076ca;
  --blue-750: #0065ad;
  --blue-800: #004d84;
  --blue-850: #002844;
  --blue-900: #001b2e;
  --blue-950: #00090f;
  --blue-core: var(--blue-300);

  /* viridian */
  --viridian-50: #f5fafa;
  --viridian-100: #e3f2f1;
  --viridian-150: #c7e5e3;
  --viridian-200: #bce0dd;
  --viridian-250: #aed9d6;
  --viridian-300: #a0d3cf;
  --viridian-350: #92ccc7;
  --viridian-400: #82c5bf;
  --viridian-450: #71bdb7;
  --viridian-500: #5eb4ad;
  --viridian-550: #4faaa2;
  --viridian-600: #499d96;
  --viridian-650: #438f89;
  --viridian-700: #3b7f79;
  --viridian-750: #326b66;
  --viridian-800: #285652;
  --viridian-825: #335452;
  --viridian-850: #132927;
  --viridian-900: #0c1a19;
  --viridian-950: #070e0d;
  --viridian-core: var(--viridian-800);

  /* green */
  --green-50: #f3fcf7;
  --green-100: #dff6e9;
  --green-150: #c0edd2;
  --green-200: #a9e6c2;
  --green-250: #9ce2ba;
  --green-300: #83dba8;
  --green-350: #6ed599;
  --green-400: #59cf8a;
  --green-450: #3dc777;
  --green-500: #37be6f;
  --green-550: #33b067;
  --green-600: #2fa35f;
  --green-650: #2b9457;
  --green-700: #26844d;
  --green-750: #207042;
  --green-800: #195632;
  --green-850: #0c2b19;
  --green-900: #081c10;
  --green-950: #030c07;
  --green-core: var(--green-500);

  /* yellow */
  --yellow-50: #fffbf0;
  --yellow-100: #fff2d1;
  --yellow-150: #ffe299;
  --yellow-200: #ffd364;
  --yellow-250: #ffc945;
  --yellow-300: #ffbf1d;
  --yellow-350: #fbb400;
  --yellow-400: #f0ac00;
  --yellow-450: #e5a400;
  --yellow-500: #d89b00;
  --yellow-550: #ca9100;
  --yellow-600: #bb8600;
  --yellow-650: #aa7a00;
  --yellow-700: #976c00;
  --yellow-750: #805c00;
  --yellow-800: #634700;
  --yellow-850: #322400;
  --yellow-900: #1f1600;
  --yellow-950: #0f0b00;
  --yellow-core: var(--yellow-250);

  /* orange */
  --orange-50: #fff7f5;
  --orange-100: #ffece5;
  --orange-150: #ffd7ca;
  --orange-200: #ffcebe;
  --orange-250: #ffc4b0;
  --orange-300: #ffb9a2;
  --orange-350: #ffae93;
  --orange-400: #ffa182;
  --orange-450: #ff936f;
  --orange-500: #ff8258;
  --orange-550: #ff6e3e;
  --orange-600: #ff5d26;
  --orange-650: #f43d00;
  --orange-700: #d93600;
  --orange-750: #b92e00;
  --orange-800: #8f2400;
  --orange-850: #4a1300;
  --orange-900: #2e0c00;
  --orange-950: #140500;
  --orange-core: var(--orange-600);

  /* grayscale */

  --gray-50: #f9f9f9;
  --gray-100: #f4f4f0;
  --gray-150: #e6e6e6;
  --gray-200: #d8d8d8;
  --gray-250: #d1d1d1;
  --gray-300: #c9c9c9;
  --gray-350: #c1c1c1;
  --gray-400: #b9b9b9;
  --gray-450: #b0b0b0;
  --gray-500: #a6a6a6;
  --gray-550: #9b9b9b;
  --gray-600: #909090;
  --gray-650: #838383;
  --gray-700: #757575;
  --gray-750: #626262;
  --gray-800: #4b4b4b;
  --gray-850: #262626;
  --gray-900: #191919;
  --gray-950: #0d0d0d;

  --white: #ffffff;
  --gray-core: var(--gray-100);
  --black: #000000;

  --color-background-disabled: var(--gray-150);

  // Forms
  --Form-Fields-Padding-vertical-small: 3px;
  --Form-Fields-Padding-horizontal-small: 16px;
  --Form-Fields-Background-disabled: var(--gray-150);
  --Form-Fields-Content-label: var(--gray-800);
  --Form-Fields-Padding-top-vertical: 18px;
  --Form-Fields-Padding-bottom-vertical: 2px;
  --Form-Fields-Padding-horizontal-small: 12px;

  // Borders
  --border-radius-default: 8px;
  --border-radius-small: 4px;
  --Form-Fields-Border-default: #d8d8d8;
  --Form-Fields-Background-default: #fff;
  --Form-Fields-Border-focus: var(--purple-400);

  // Errors
  --Form-Fields-Border-error: var(--pink-700);

  // Buttons
  --Button-Color-Primary-main: var(--viridian-800);

  // Text
  --content-alt: var(--gray-800);
}

/* typefaces */

@font-face {
  font-family: 'Fakt-Normal';
  src: url('/assets/fonts/Fakt/Fakt-Normal.woff') format('woff');
}
@font-face {
  font-family: 'Fakt-Bold';
  src: url('/assets/fonts/Fakt/Fakt-Bold.woff') format('woff');
}
@font-face {
  font-family: 'PitchSans-Medium';
  src: url('/assets/fonts/PitchSans/OTF/PitchSans-Medium.otf')
      format('opentype'),
    url('/assets/fonts/PitchSans/TTF/PitchSans-Medium.ttf') format('truetype'),
    url('/assets/fonts/PitchSans/WOFF2/pitch-sans-medium.woff2') format('woff');
}
@font-face {
  font-family: 'PitchSans-Bold';
  src: url('/assets/fonts/PitchSans/OTF/PitchSans-Bold.otf') format('opentype'),
    url('/assets/fonts/PitchSans/TTF/PitchSans-Bold.ttf') format('truetype'),
    url('/assets/fonts/PitchSans/WOFF2/pitch-sans-bold.woff2') format('woff');
}
a {
  text-decoration: none;
}

// Should remain at the end
@import './utils';
