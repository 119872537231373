ion-button.poplin-theme {
  --box-shadow: none;
  --border-radius: var(--border-radius-default);

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.3px;

  &.button-disabled {
    opacity: 1;
  }

  &.h-large {
    --border-radius: 8px;
    height: 48px;
    font-size: 16px;
    line-height: 20px;
  }

  span.title {
    margin-right: 10px;
  }
}

ion-button[disabled].poplin-theme {
  --ion-color-base: var(--gray-650) !important;
}
