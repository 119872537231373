@import 'src/theme/type-mixins.scss';

ion-modal.poplin-theme {
  --border-radius: var(--border-radius-default);
  --box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);

  ion-toolbar {
    padding-top: 0;
  }

  ion-header {
    box-shadow: none;

    ion-title {
      @include l-alt-head;
    }
  }

  ion-content {
    --padding-end: 10px;
    --padding-start: 10px;
  }
}

// Misc
ion-modal.datepicker-modal {
  --height: 670px;
  --width: 95%;
  --border-radius: var(--border-radius-default);
  --box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);

  &.modal-default {
    top: 0;
    bottom: 0;
  }
}
