// http://ionicframework.com/docs/theming/
@import 'type-mixins';

@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~swiper/swiper-bundle.min.css';

@font-face {
  font-family: Roboto;
  font-weight: 200;
  src: url(assets/fonts/Roboto/Roboto-Thin.ttf) format('opentype');
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  src: url(assets/fonts/Roboto/Roboto-Light.ttf) format('opentype');
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  src: url(assets/fonts/Roboto/Roboto-Regular.ttf) format('opentype');
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  src: url(assets/fonts/Roboto/Roboto-Medium.ttf) format('opentype');
}

@font-face {
  font-family: Roboto;
  font-weight: 600;
  src: url(assets/fonts/Roboto/Roboto-Bold.ttf) format('opentype');
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  src: url(assets/fonts/Roboto/Roboto-Black.ttf) format('opentype');
}

*:focus {
  outline: 0;
}

.material-icons {
  font-display: block;
}

.shake {
  -webkit-animation: shake-horizontal 0.8s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation-iteration-count: infinite;
}

.MenuPopover {
  --width: 220px;
}

@supports (-webkit-touch-callout: none) {
  .modal-default {
    top: calc(
      env(safe-area-inset-top) + 40px
    ); /* Adds padding from the top notch */
    bottom: calc(env(safe-area-inset-bottom) - 20px);
  }
}

/* For additional fallback on older iOS versions */
@media only screen and (max-device-width: 812px) {
  .modal-default {
    top: 40px; /* Adjust this value to your liking */
    bottom: 20px;
  }
}

@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

.alert-wrapper {
  .danger {
    color: red !important;
  }

  .secondary {
    color: var(--BlueGray3) !important;
  }

  ul {
    li {
      padding-bottom: 10px;
    }
  }
}

.alert-message.sc-ion-alert-md {
  max-height: 288px;
}

.valign {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-shadow {
  pointer-events: none;
}

ion-modal.PopupModal {
  --width: 90%;
  --max-height: 480px;
  --max-width: 400px;
  --border-radius: 5px;
}

.PopupModal {
  .modal-wrapper {
    width: 90%;
    max-height: 480px;
    max-width: 400px;
    border-radius: 5px;
  }
}

ion-modal.LargePopupModal {
  --width: 90%;
  --max-height: 95%;
  padding-top: 13%;
  --max-width: 400px;
  --border-radius: 5px;
}

.LargePopupModal {
  .modal-wrapper {
    width: 90%;
    max-height: 480px;
    max-width: 400px;
    border-radius: 5px;
  }
}

ion-modal.SmallPopupModal {
  --width: 90%;
  --max-height: 350px;
  --max-width: 400px;
  --border-radius: 5px;
  --overflow-y: scroll;
}

.SmallPopupModal {
  app-payoutsetup {
    padding: 10px;
    overflow-y: scroll;
  }

  .modal-wrapper {
    width: 90%;
    max-height: 300px;
    max-width: 400px;
    border-radius: 5px;
  }

  app-payout-settings {
    justify-content: space-evenly;
    max-height: 363px;
  }
}

.settings-popover {
  --width: 280px;
}

.ModalHeader label {
  @include xs-head;
  display: block;
  text-align: center;
}

.ModalHeader ion-icon {
  position: absolute;
  left: 15px;
  top: 18px;
  font-size: 25px;
}

.ModalHeader {
  height: 60px;
  margin-top: env(safe-area-inset-top);
  position: relative;
}

ion-header ion-title {
  @include xs-head;
}

.trunc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grecaptcha-badge {
  visibility: hidden;
}

.button-block {
  max-width: 300px;
  margin: 0 auto;
}

ion-button {
  ion-icon {
    margin-left: 7px;
    margin-right: -7px;
  }

  margin: 10px auto;
}

.maxwidth {
  max-width: 300px;
  margin: 0 auto;
}

.InputDiv {
  .header {
    display: inline-flex;

    .label {
      width: 80%;
      margin-left: 10%;
      margin-top: -12%;
    }
  }

  width: 90%;
  display: block;
  max-width: 300px;
  margin: 10px auto;
  padding: 5px 10px;
  border-radius: 5px;
  border-bottom: solid 2px var(--BlueGray2);
  min-height: 40px;
  overflow: hidden;

  .ng-invalid.ng-touched {
    border-right: solid 3px var(--Red);
  }

  small {
    display: block;
    font-size: 12px;
    text-align: center;
    line-height: 120%;
    clear: both;
    padding: 7px;
    color: var(--BlueGray4);
    font-weight: 700;
  }

  label {
    display: block;
    font-size: 15px;
    text-align: center;
    line-height: 120%;
    clear: both;
    padding: 7px;
    color: var(--BlueGray4);
    font-weight: 700;
  }

  img {
    height: 35px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
    margin-bottom: -5px;
  }

  input {
    background: none;
    border: none;
    padding-left: 10px;
    float: right;
    display: block;
    width: calc(100% - 20px);
    line-height: 26px;
  }

  input::placeholder {
    color: var(--BlueGray4);
  }

  i {
    font-size: 18px;
    color: var(--BlueGray4);
    display: block;
    float: left;
    height: 100%;
    line-height: 30px;
  }

  .SmallInput {
    display: inline-block;
    float: left;
    width: calc(50% - 10px);
  }
}

.InputDiv:focus-within {
  border-color: var(--Blue);

  i {
    color: var(--Blue);
  }
}

.ICEForm {
  display: grid;
  padding-left: 20px;
}

.ICER {
  padding-left: 10px;
}

ion-select {
  --placeholder-color: var(--BlueGray4);
  --placeholder-opacity: 1;
}

ion-textarea.ion-valid.sc-ion-textarea-md-h {
  --highlight-color: transparent;
}

.InputSegment {
  width: 90%;
  max-width: 300px;
  margin: 0 auto;
  border-radius: 5px;
  height: 35px;

  ion-segment-button {
    border: none;
    color: var(--BlueGray4);
  }

  .segment-button-checked {
    color: var(--pink-core);
  }
}

.checkmarkDiv {
  width: 100px;
  height: 30px;
  margin: 0 auto;

  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: var(--Blue);
    fill: none;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px var(--Blue);
    -webkit-animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
    animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }

  .checkmark__check {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @-webkit-keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes scale {
    0%,
    100% {
      -webkit-transform: none;
      transform: none;
    }

    50% {
      -webkit-transform: scale3d(1.1, 1.1, 1);
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes scale {
    0%,
    100% {
      -webkit-transform: none;
      transform: none;
    }

    50% {
      -webkit-transform: scale3d(1.1, 1.1, 1);
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @-webkit-keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px var(--Blue);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px var(--Blue);
    }
  }
}

.dark-toast {
  --background: var(--viridian-825);
  --color: white;
  --button-color: white;
}

.order-contract-modal-xs,
.order-contract-modal-small,
.order-contract-modal-medium,
.order-contract-modal-bit {
  --max-height: 90vh;
  --width: 96vw;
  --max-width: 400px;
  --border-radius: 10px 10px 0 0;

  .modal-wrapper {
    max-height: 90vh !important;
    width: 96vw !important;
    max-width: 400px;
    position: absolute !important;
    bottom: 0px !important;
    border-radius: 15px 15px 0 0 !important;
  }
}

.order-contract-modal-xs {
  --height: 45vh;
}

.order-contract-modal-small {
  --height: 90vh;
  --min-height: 420px;

  .modal-wrapper {
    min-height: 420px;
    height: 90vw !important;
  }
}

.order-contract-modal-medium {
  --height: 90vw;
  --min-height: 490px;

  .modal-wrapper {
    min-height: 490px !important;
    height: 90vw !important;
  }
}

.order-contract-modal-big {
  --height: 90vw;
  --min-height: 570px;

  .modal-wrapper {
    min-height: 570px !important;
    height: 90vw !important;
  }
}

.alert-radio-label.sc-ion-alert-md {
  white-space: normal !important;
  padding-left: 15%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-right: 5%;
}

ion-modal.verification-confirm-component {
  --max-width: 425px;
}

.alert-tappable.sc-ion-alert-md {
  display: contents;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.network-error-toast {
  &::part(header) {
    font-family: 'PitchSans-Medium', 'Helvetica' sans-serif;
    text-transform: uppercase;
    font-weight: 700;
  }
}

ion-modal.order-extension-modal {
  --width: 90%;
  --max-height: 440px;
  --max-width: 400px;
  --border-radius: 5px;
  --overflow-y: scroll;
}

ion-action-sheet.inbox-message-action-sheet {
  .action-sheet-group {
    padding: 5px 13px;
  }

  .action-sheet-title {
    font-weight: 700;
    color: var(--gray-900);

    .action-sheet-sub-title {
      color: var(--content-alt);
      font-weight: 400;
    }
  }

  .action-sheet-button {
    box-shadow: none;
    background-color: transparent;
    color: var(--Button-Color-Primary-main);
    text-decoration: underline;
    text-underline-offset: 0.2em;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 1.3px;

    &:hover {
      color: var(--Button-Color-Primary-main);
    }

    &.button-disabled {
      opacity: 1;
    }

    .action-sheet-button-inner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .inbox-message-button-primary {
    color: var(--white);
    background-color: var(--Button-Color-Primary-main);
    border-radius: var(--border-radius-small);
    text-decoration: none;

    &:hover {
      color: var(--white);
      background-color: var(--color-button-primary-hover);
    }
  }
}
