ion-toast {
  --border-radius: var(--border-radius-default);

  &::part(container) {
    background: transparent;
    border-radius: var(--border-radius-default);
  }

  &::part(header) {
    font-family: 'PitchSans-Medium', 'Helvetica', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2.1px;
  }

  &::part(message) {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &::part(icon) {
    border-radius: 50%;
    border: 3px solid white;
  }
}

.success-toast {
  &::part(container) {
    background: var(--green-350);
    color: var(--green-900);
  }

  &::part(icon) {
    color: var(--green-500);
    background: white;
  }
}

.error-toast {
  &::part(container) {
    background: var(--pink-400);
    color: var(--pink-950);
  }

  &::part(icon) {
    color: var(--pink-500);
    background: white;
  }
}
