// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --BlueGray1: var(--gray-50);
  --BlueGray2: var(--gray-300);
  --BlueGray3: var(--gray-600);
  --BlueGray4: var(--gray-700);
  --BlueGray5: var(--gray-800);

  --DarkBlue: rgb(10, 132, 255);

  --Blue: var(--blue-core);
  --Yellow: var(--yellow-core);
  --Orange: var(--yellow-core);
  --Pink: var(--pink-core);
  --Green: var(--green-core);
  --GreenTint: var(--green-350);
  --Red: #e74c3c;

  /** primary **/
  --ion-color-primary: var(--viridian-800);
  --ion-color-primary-rgb: 255, 98, 137;
  --ion-color-primary-contrast: var(--white);
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--viridian-800);
  --ion-color-primary-tint: var(--viridian-800);

  /** secondary **/

  --ion-color-secondary: var(--viridian-core);
  --ion-color-secondary-rgb: 40, 86, 82;
  --ion-color-secondary-contrast: var(--viridian-100);
  --ion-color-secondary-contrast-rgb: 227, 242, 241;

  --ion-color-secondary-shade: var(--viridian-950);
  --ion-color-secondary-tint: var(--viridian-650);

  /** tertiary **/

  --ion-color-tertiary: var(--purple-core);
  --ion-color-tertiary-rgb: 192, 175, 226;
  --ion-color-tertiary-contrast: var(--white);
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;

  --ion-color-tertiary-shade: var(--purple-550);
  --ion-color-tertiary-tint: var(--purple-250);

  /** success **/

  --ion-color-success: var(--green-core);
  --ion-color-success-rgb: 55, 190, 111;
  --ion-color-success-contrast: var(--green-900);
  --ion-color-success-contrast-rgb: 8, 28, 16;

  --ion-color-success-shade: var(--green-650);
  --ion-color-success-tint: var(--green-350);

  /** warning **/

  --ion-color-warning: var(--yellow-core);
  --ion-color-warning-rgb: 255, 201, 69;
  --ion-color-warning-contrast: var(--yellow-850);
  --ion-color-warning-contrast-rgb: 50, 36, 0;

  --ion-color-warning-shade: var(--yellow-400);
  --ion-color-warning-tint: var(--yellow-100);

  /** danger **/

  --ion-color-danger: var(--orange-core);
  --ion-color-danger-rgb: 255, 93, 38;
  --ion-color-danger-contrast: var(--black);
  --ion-color-danger-contrast-rgb: 0, 0, 0;

  --ion-color-danger-shade: var(--orange-750);
  --ion-color-danger-tint: var(--orange-450);

  /** dark **/

  --ion-color-dark: var(--gray-850);
  --ion-color-dark-rgb: 38, 38, 38;
  --ion-color-dark-contrast: var(--white);
  --ion-color-dark-contrast-rgb: 255, 255, 255;

  --ion-color-dark-shade: var(--black);
  --ion-color-dark-tint: var(--gray-700);

  /** medium **/

  --ion-color-medium: var(--gray-700);
  --ion-color-medium-rgb: 117, 117, 117;
  --ion-color-medium-contrast: var(--black);
  --ion-color-medium-contrast-rgb: 0, 0, 0;

  --ion-color-medium-shade: var(--gray--850);
  --ion-color-medium-tint: var(--gray--550);

  /** light **/

  --ion-color-light: var(--white);
  --ion-color-light-rgb: 223, 223, 223;
  --ion-color-light-contrast: var(--viridian-800);
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: var(--white);
  --ion-color-light-tint: var(--white);

  /* main colors */

  --ion-font-family: 'Fakt-Normal', 'Roboto', Helvetica, sans-serif;
}
