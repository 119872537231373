/*
    * Use these semantic color variables
    only as directed by Figma design.

    * Use them semantically (i.e. don't use
    "background" colors for text.

    * These colors change based on light vs
    dark theme. Use these especially when transition
    between light and dark modes are needed.
*/
:root {
  --color-background-primary: var(--white);
  --color-background-alt: var(--gray-50);
  --color-background-dim: var(--gray-core);

  --color-content-primary: var(--black);
  --color-content-alt: var(--gray-800);
  --color-content-med-contrast: var(--gray-700);
  --color-content-low-contrast: var(--gray-650);

  --color-content-inverse: var(--white);
  --color-content-inverse-alt: var(--gray-400);

  --color-content-link: var(--blue-750);
  --color-content-link-hover: var(--blue-400);
  --color-content-link-active: var(--blue-200);
  --color-content-link-visited: var(--purple-750);

  --color-border-primary: var(--gray-600);
  --color-border-alt: var(--gray-200);
  --color-border-selected: var(--purple-core);

  // form field colors

  --form-fields-border-default: var(--color-border-alt);
  --form-fields-border-disabled: var(--gray-250);
  --form-fields-border-read-only: var(--gray-100);

  --form-fields-background-default: var(--white);
  --form-fields-background-disabled: var(--gray-150);

  --form-fields-content-label: var(--color-content-alt);
  --form-fields-content-value: var(--color-content-primary);
  --form-fields-content-value-autofill: var(--blue-750);

  --form-fields-content-message-error: var(--color-content-primary);
  --form-fields-content-message-help: var(--color-content-alt);
  --form-fields-content-icon-error: var(--pink-shade);

  // button colors

  --color-button-primary-main: var(--viridian-core);
  --color-button-primary-alt: var(--white);
  --color-button-primary-disabled: var(--gray-650);
  --color-button-primary-hover: var(--viridian-700);
  --color-button-primary-active: var(--viridian-650);
  --color-button-primary-wash: var(--viridian-100);

  --color-button-monochrome-main: var(--black);
  --color-button-monochrome-alt: var(--white);
  --color-button-monochrome-disabled: var(--gray-700);
  --color-button-monochrome-hover: var(--gray-800);
  --color-button-monochrome-active: var(--gray-750);
  --color-button-monochrome-wash: var(--gray-100);

  --color-button-inverse-monochrome-main: var(--white);
  --color-button-inverse-monochrome-alt: var(--black);
  --color-button-inverse-monochrome-disabled: var(--gray-300);
  --color-button-inverse-monochrome-hover: var(--gray-100);
  --color-button-inverse-monochrome-active: var(--gray-150);
  --color-button-inverse-monochrome-wash: var(--gray-850);

  --color-button-black-main: var(--black);
  --color-button-black-alt: var(--white);
  --color-button-black-disabled: var(--gray-700);
  --color-button-black-hover: var(--gray-800);
  --color-button-black-active: var(--gray-750);
  --color-button-black-wash: var(--gray-100);

  --color-button-white-main: var(--white);
  --color-button-white-alt: var(--black);
  --color-button-white-disabled: var(--gray-300);
  --color-button-white-hover: var(--gray-100);
  --color-button-white-active: var(--gray-150);
  --color-button-white-wash: var(--gray-850);

  --color-button-red-main: var(--pink-700);
  --color-button-red-alt: var(--pink-700);
  --color-button-red-disabled: var(--gray-300);
  --color-button-red-hover: var(--gray-100);
  --color-button-red-active: var(--gray-150);
  --color-button-red-wash: var(--gray-850);

  --color-round-arrow-button-background: var(--white);
  --color-round-arrow-button-icon: var(--pink-core);
  --color-round-arrow-button-text: var(--black);
  --color-round-arrow-button-disabled: var(--gray-250);

  --color-round-arrow-button-text-hover: var(--pink-core);
  --color-round-arrow-button-text-active: var(--pink-shade);

  --color-auth-button-background: var(--white);
  --color-auth-button-border: var(--gray-300);
  --color-auth-button-text: var(--gray-750);
  --color-auth-button-disabled: var(--color-content-med-contrast);

  --color-auth-button-border-hover: var(--gray-500);
  --color-auth-button-text-hover: var(--gray-950);
  --color-auth-button-border-active: var(--gray-550);
  --color-auth-button-text-active: var(--black);

  // item colors

  --color-item-background: var(--color-background-primary);
  --color-item-background-hover: var(--color-background-alt);
  --color-item-background-active: var(--pink-wash);

  // chip colors

  --color-chip-primary-main: var(--blue-core);
  --color-chip-primary-alt: var(--black);
  --color-chip-primary-disabled: var(--blue-tint);

  --color-chip-monochrome-main: var(--black);
  --color-chip-monochrome-alt: var(--white);
  --color-chip-monochrome-disabled: var(--gray-700);

  --color-chip-inverse-monochrome-main: var(--white);
  --color-chip-inverse-monochrome-alt: var(--black);
  --color-chip-inverse-monochrome-disabled: var(--gray-300);

  --color-chip-black-main: var(--black);
  --color-chip-black-alt: var(--white);
  --color-chip-black-disabled: var(--gray-700);

  --color-chip-white-main: var(--white);
  --color-chip-white-alt: var(--black);
  --color-chip-white-disabled: var(--gray-300);

  // notification colors
  --notification-color-icon-error: var(--pink-core);
  --notification-color-content-error: var(--pink-contrast);
  --notification-color-background-error: var(--pink-tint);

  --notification-color-icon-success: var(--green-core);
  --notification-color-content-success: var(--green-contrast);
  --notification-color-background-success: var(--green-tint);

  --notification-color-icon-caution: var(--yellow-core);
  --notification-color-content-caution: var(--yellow-contrast);
  --notification-color-background-caution: var(--yellow-tint);

  --notification-color-icon-warning: var(--orange-core);
  --notification-color-content-warning: var(--orange-contrast);
  --notification-color-background-warning: var(--orange-tint);

  --notification-color-icon-info: var(--blue-shade);
  --notification-color-content-info: var(--blue-contrast);
  --notification-color-background-info: var(--blue-tint);

  --notification-color-icon-default: var(--viridian-shade);
  --notification-color-content-default: var(--viridian-contrast);
  --notification-color-background-default: var(--viridian-core);

  // number stepper colors

  // --number-stepper-color-background-default: var(--white);
  // --number-stepper-color-border-default: var(--color-border-alt);

  --number-stepper-color-background-disabled: var(--gray-150);
  --number-stepper-color-border-disabled: var(--gray-250);

  // wizard colors
  --wizard-active-color: var(--pink-650);
  --wizard-unselected-color: var(--gray-500);

  // Password Strength colors
  --password-strength-meter-color-weak: var(--pink-tint);
  --password-strength-meter-color-medium: var(--yellow-core);
  --password-strength-meter-color-strong: var(--green-core);

  // Footer Nav colors
  --footer-nav-active-icon-background: var(--pink-core);
  --footer-nav-svg-color: var(--black);
  --footer-nav-svg-active-color: var(--white);
  --footer-nav-color-text-current: var(--black);

  // toggle button colors
  --toggle-button-color-main: var(--viridian-core);
  --toggle-button-color-alt: var(--white);
  --toggle-button-color-disabled: var(--gray-650);

  // card tokens
  --card-color-background-default: var(--color-background-primary);
  --card-color-background-unselected: var(--color-background-alt);
  --card-color-background-unavailable: var(--gray-100);
  --card-color-border-default: var(--color-border-alt);
  --card-color-border-selected: var(--viridian-core);

  --card-symbol-color-background: var(--pink-core);
  --card-symbol-color-background-success: var(--green-core);
  --card-symbol-color-background-pending: var(--blue-core);
  --card-symbol-color-background-dim: var(--gray-500);
  --card-symbol-color-background-alert: var(--yellow-core);
  --card-symbol-color-background-alert-strong: var(--orange-core);
  --card-symbol-color-background-error: var(--pink-shade);
  --card-symbol-color-icon: var(--white);

  // rating tokens
  --ratings-color-default: var(--color-content-alt);
  --ratings-color-great: var(--green-core);
  --ratings-color-better: var(--blue-core);
  --ratings-color-okay: var(--yellow-core);
  --ratings-color-poor: var(--orange-core);
  --ratings-color-terrible: var(--pink-shade);

  // bead wizard
  --bead-color-checked: var(--pink-core);
  --bead-color-unchecked: var(--gray-300);
  --bead-color-complete: var(--green-core);
  --bead-color-pending: var(--blue-core);
  --bead-color-alert: var(--orange-core);

  // radio button
  --radio-icon-background: var(--white);
  --radio-icon-border-unchecked: var(--gray-150);
  --radio-icon-border-checked: var(--viridian-core);
  --radio-icon-background-disabled: var(--gray-250);
  --radio-icon-border-disabled: var(--gray-250);
  --radio-icon-border-checked-disabled: var(--gray-700);
  --radio-icon-border-error: var(--pink-shade);

  // checkbox colors
  --checkbox-icon-background-unchecked: var(--white);
  --checkbox-icon-border-unchecked: var(--gray-400);
  --checkbox-icon-background-checked: var(--viridian-core);
  --checkbox-icon-border-checked: var(--viridian-core);
  --checkbox-icon-background-disabled: var(--gray-250);
  --checkbox-icon-border-disabled: var(--gray-250);
  --checkbox-icon-unchecked: var(--white);
  --checkbox-icon-checked: var(--white);
  --checkbox-icon-disabled: var(--gray-700);
  --checkbox-icon-border-error: var(--pink-shade);
  --checkbox-icon-background-checked-error: var(--pink-shade);

  // messaging
  --messaging-background-poplin: var(--pink-tint);
  --messaging-background-laundry-pro: var(--gray-150);
  --messaging-background-customer: var(--blue-core);
  --messaging-content: var(--black);

  // select & menu
  --color-select-option-selected: var(--pink-wash);
  --color-select-checkmark: var(--pink-core);
  --color-select-dropdown-indicator: var(--gray-700);
}

body.dark {
  --color-background-primary: var(--gray-950);
  --color-background-alt: var(--gray-900);
  --color-background-dim: var(--gray-900);

  --color-content-primary: var(--white);
  --color-content-alt: var(--gray-350);
  --color-content-med-contrast: var(--gray-450);
  --color-content-low-contrast: var(--gray-650);

  --color-content-inverse: var(--black);
  --color-content-inverse-alt: var(--gray-800);

  --color-content-link: var(--blue-600);
  --color-content-link-hover: var(--white);
  --color-content-link-active: var(--blue-200);
  --color-content-link-visited: var(--purple-600);

  --color-border-primary: var(--gray-500);
  --color-border-alt: var(--gray-800);
  --color-border-selected: var(--purple-core);

  // form field colors

  --form-fields-border-default: var(--color-border-alt);
  --form-fields-border-disabled: var(--gray-650);
  --form-fields-border-read-only: var(--gray-900);

  --form-fields-background-default: var(--gray-850);
  --form-fields-background-disabled: var(--gray-900);

  --form-fields-content-label: var(--color-content-alt);
  --form-fields-content-value: var(--color-content-primary);
  --form-fields-content-value-autofill: var(--blue-600);

  --form-fields-content-message-error: var(--color-content-primary);
  --form-fields-content-message-help: var(--color-content-alt);
  --form-fields-content-icon-error: var(--pink-shade);

  // button colors

  --color-button-primary-main: var(--white);
  --color-button-primary-alt: var(--black);
  --color-button-primary-disabled: var(--gray-300);
  --color-button-primary-hover: var(--gray-100);
  --color-button-primary-active: var(--gray-150);
  --color-button-primary-wash: var(--viridian-850);

  --color-button-monochrome-main: var(--white);
  --color-button-monochrome-alt: var(--black);
  --color-button-monochrome-disabled: var(--gray-300);
  --color-button-monochrome-hover: var(--gray-100);
  --color-button-monochrome-active: var(--gray-150);
  --color-button-monochrome-wash: var(--gray-850);

  --color-button-inverse-monochrome-main: var(--black);
  --color-button-inverse-monochrome-alt: var(--white);
  --color-button-inverse-monochrome-disabled: var(--gray-700);
  --color-button-inverse-monochrome-hover: var(--gray-800);
  --color-button-inverse-monochrome-active: var(--gray-750);
  --color-button-inverse-monochrome-wash: var(--gray-100);

  // black and white buttons are the same in light and dark mode

  --color-round-arrow-button-background: var(--black);
  --color-round-arrow-button-icon: var(--white);
  --color-round-arrow-button-text: var(--white);
  --color-round-arrow-button-disabled: var(--gray-850);

  --color-round-arrow-button-text-hover: var(--gray-100);
  --color-round-arrow-button-text-active: var(--gray-150);

  --color-auth-button-background: var(--black);
  --color-auth-button-border: var(--gray-700);
  --color-auth-button-text: var(--gray-250);

  --color-auth-button-border-hover: var(--gray-800);
  --color-auth-button-text-hover: var(--gray-500);
  --color-auth-button-border-active: var(--gray-850);
  --color-auth-button-text-active: var(--gray-450);

  // item colors

  --color-item-background: var(--color-background-primary);
  --color-item-background-hover: var(--color-background-alt);
  --color-item-background-active: var(--pink-900);

  // chip colors

  --color-chip-primary-main: var(--blue-700);
  --color-chip-primary-alt: var(--white);
  --color-chip-primary-disabled: var(--blue-800);

  --color-chip-monochrome-main: var(--white);
  --color-chip-monochrome-alt: var(--black);
  --color-chip-monochrome-disabled: var(--gray-300);

  --color-chip-inverse-monochrome-main: var(--black);
  --color-chip-inverse-monochrome-alt: var(--white);
  --color-chip-inverse-monochrome-disabled: var(--gray-700);

  // white and black chips are the same in light and dark mode

  // checkbox colors

  --color-checkbox-background-checked: var(--white);
  --color-checkbox-background-checked-disabled: var(--gray-750);
  --color-checkbox-checkmark: var(--black);
  --color-checkbox-checkmark-disabled: var(--gray-300);
  --color-checkbox-background: var(--gray-850);
  --color-checkbox-background-disabled: var(--gray-750);
  --color-checkbox-border: var(--gray-600);
  --checkbox-text-label-unchecked: var(--white);
  --checkbox-text-label-checked: var(--white);

  //select color
  --select-menu-color-background-selected: var(--black);

  // number stepper colors

  // --number-stepper-color-background-default: var(--gray-850);
  // --number-stepper-color-border-default: var(--color-border-alt);

  --number-stepper-color-background-disabled: var(--gray-950);
  --number-stepper-color-border-disabled: var(--gray-750);

  // notification colors
  --notification-color-icon-error: var(--pink-core);
  --notification-color-background-error: var(--pink-core);

  --notification-color-icon-caution: var(--yellow-shade);
  --notification-color-background-caution: var(--yellow-shade);

  --notification-color-icon-warning: var(--orange-shade);
  --notification-color-content-warning: var(--orange-wash);
  --notification-color-background-warning: var(--orange-shade);

  --notification-color-icon-info: var(--blue-contrast);
  --notification-color-content-info: var(--blue-tint);
  --notification-color-background-info: var(--blue-contrast);

  --notification-color-icon-default: var(--viridian-core);
  --notification-color-content-default: var(--viridian-shade);
  --notification-color-background-default: var(--viridian-tint);

  // wizard colors
  --wizard-active-color: var(--white);
  --wizard-unselected-color: var(--gray-600);

  // Password Strength colors
  --password-strength-meter-color-weak: var(--pink-shade);
  --password-strength-meter-color-medium: var(--yellow-shade);
  --password-strength-meter-color-strong: var(--green-shade);

  // Footer Nav colors
  --footer-nav-svg-color: var(--white);
  --footer-nav-svg-active-color: var(--black);
  --footer-nav-active-icon-background: var(--white);
  --footer-nav-color-text-current: var(--white);

  // toggle button colors
  --toggle-button-color-main: var(--white);
  --toggle-button-color-alt: var(--black);
  --toggle-button-color-disabled: var(--gray-700);

  // card tokens
  --card-color-background-default: var(--color-background-primary);
  --card-color-background-unselected: var(--color-background-alt);
  --card-color-background-unavailable: var(--gray-850);
  --card-color-border-default: var(--color-border-alt);
  --card-color-border-selected: var(--white);

  --card-symbol-color-background-pending: var(--purple-core);
  --card-symbol-color-icon: var(--black);

  // ratings stars
  --ratings-color-default: var(--gray-350);
  --ratings-color-terrible: var(--pink-700);
  --ratings-color-poor: var(--orange-600);
  --ratings-color-okay: var(--yellow-250);
  --ratings-color-better: var(--blue-300);
  --ratings-color-great: var(--green-500);

  // bead wizard
  --bead-color-checked: var(--white);
  --bead-color-unchecked: var(--gray-700);
  --bead-color-complete: var(--viridian-core);
  --bead-color-pending: var(--purple-core);
  --bead-color-alert: var(--orange-core);

  // radio button
  --radio-icon-background: var(--gray-850);
  --radio-icon-border-unchecked: var(--gray-600);
  --radio-icon-border-checked: var(--white);
  --radio-icon-background-disabled: var(--gray-750);
  --radio-icon-border-disabled: var(--gray-750);
  --radio-icon-border-checked-disabled: var(--gray-300);
  --radio-icon-border-error: var(--pink-shade);

  // checkbox colors

  --checkbox-icon-background-unchecked: var(--gray-850);
  --checkbox-icon-border-unchecked: var(--gray-400);
  --checkbox-icon-background-checked: var(--white);
  --checkbox-icon-border-checked: var(--white);
  --checkbox-icon-background-disabled: var(--gray-750);
  --checkbox-icon-border-disabled: var(--gray-750);
  --checkbox-icon-unchecked: var(--black);
  --checkbox-icon-checked: var(--black);
  --checkbox-icon-disabled: var(--gray-300);
  --checkbox-icon-border-error: var(--pink-shade);
  --checkbox-icon-background-checked-error: var(--pink-shade);

  // messaging
  --messaging-background-poplin: var(--viridian-core);
  --messaging-background-laundry-pro: var(--gray-750);
  --messaging-background-customer: var(--blue-800);
  --messaging-content: var(--white);

  // select & menu
  --color-select-option-selected: var(--pink-contrast);
  --color-select-checkmark: var(--pink-core);
  --color-select-dropdown-indicator: var(--gray-700);
}
